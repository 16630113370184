/* eslint-disable complexity */
import React, { useCallback }  from 'react'

import { push } from 'connected-react-router'
import { composeP } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'

import FormAuthComponent from 'components/FormAuth'
import { desktopMediaQuery, mobileMediaQuery, setDevice, tabletMediaQuery } from 'redux/modules/device'
import { showModal } from 'redux/modules/modal'
import { fetch as fetchPersonal, resetStore } from 'redux/modules/personal'
import { pathnameSearchSelector, pathnameSelector } from 'redux/modules/settings'
import { getTokenByPassword, tokenErrorSelector } from 'redux/modules/token'

const SLICE_FOR_URL_SEARCH = 3
const TIMEOUT_FOR_EXTERNAL_URL = 1000

const FormAuth = props => {
  const dispatch = useDispatch()

  const pathname = useSelector(({ router }) => pathnameSelector(router))

  const search = useSelector(({ router }) => pathnameSearchSelector(router))

  const tokenError = useSelector(({ token }) => tokenErrorSelector(token))

  const onShowModal = useCallback(
    params => dispatch(showModal(params)),
    [dispatch]
  )

  const onResetStore = useCallback(
    data => dispatch(resetStore(data)),
    [dispatch]
  )

  const onPush = useCallback(
    params => dispatch(push(params)),
    [dispatch]
  )

  const onGetTokenByPassword = useCallback(
    data => dispatch(getTokenByPassword(data)),
    [dispatch]
  )

  const onFetchPersonal = useCallback(
    data => dispatch(fetchPersonal(data)),
    [dispatch]
  )

  const onDetectDevice = useCallback(
    data => dispatch(setDevice(data)),
    [dispatch]
  )

  const onSetDevice = useCallback(e => e.matches && onDetectDevice(e.media), [onDetectDevice])

  const onAuth = useCallback(({ email, password }) =>
    onGetTokenByPassword({
      username: email.trim(),
      password: password.trim(),
      onSuccess: () => {
        const isAuth = pathname === '/auth'
        const toUrl = isAuth ? search
          .split('')
          .slice(SLICE_FOR_URL_SEARCH)
          .join('') : pathname
        const urlParams = new URLSearchParams(search)
        const url = isAuth && urlParams.get('p')
        const isExternalUrl = url && new URL(url).hostname !== window.location.hostname

        composeP(
          () => isExternalUrl ? setTimeout(() => { window.location.href = url }, TIMEOUT_FOR_EXTERNAL_URL) : null,
          () => {
            onSetDevice(mobileMediaQuery)
            onSetDevice(tabletMediaQuery)
            onSetDevice(desktopMediaQuery)
          },
          () => onFetchPersonal(),
          () => onResetStore(),
          () => onPush(isExternalUrl ? '/' : toUrl)
        )()
      }
    }), [onFetchPersonal, onGetTokenByPassword, onPush, onResetStore, onSetDevice, pathname, search])

  return <FormAuthComponent
    authError={tokenError}
    onSubmit={onAuth}
    showModal={onShowModal}
    {...props}
  />
}

export default FormAuth
