/* eslint-disable complexity */
import React, { useState } from 'react'

import cx from 'classnames'
import { push } from 'connected-react-router'
import { string, object, bool } from 'prop-types'
import { compose, length, map, not, prop, propOr, slice } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'

import CurrencyValue from 'components/CurrencyValue'
import Image from 'components/Image'
import Tooltip from 'components/Tooltip'
import { display } from 'decorators/device'
import { setAnalyticsProps } from 'helpers/metaTags'
import { modifiersSelector } from 'redux/modules/productList/selector'
import Text from 'UI/Text'

import s from './ProductOne.scss'

const DEFAULT_OPEN = 4

const ModifierItem = ({ isDesktop, modifier }) => {
  const dispatch = useDispatch()

  const onNavigate = id => () => {
    dispatch(push(`/catalog/product/${id}`))
  }

  const linkToModifier = (
    <div
      className={cx(s.modifierItem, {
        [s.modifierItemCurrent]: propOr(false, 'CURRENT', modifier),
        [s.modifierItemInactive]: !propOr(true, 'ACTIVE', modifier)
      })}
      onClick={onNavigate(propOr(false, 'PRODUCT', modifier))}
      role='presentation'
      {...setAnalyticsProps({
        type: 'action',
        group: 'productCard',
        action: 'innerFilterName'
      })}
    >
      {prop('TITLE', modifier)}
    </div>
  )

  if (!isDesktop) {
    return linkToModifier
  }

  return (
    <Tooltip
      tooltipNode={(
        <div
          className={s.tooltip}
          onClick={onNavigate(propOr(false, 'PRODUCT', modifier))}
          role='presentation'
          {...setAnalyticsProps({
            type: 'action',
            group: 'productCard',
            action: 'innerFilterValue'
          })}
        >
          <Image
            backgroundSize='contain'
            className={s.tooltipImage}
            src={prop('IMAGE', modifier)}
          />
          {propOr(false, 'ACTIVE', modifier) ? (
            <div className={s.tooltipPrice}>
              <CurrencyValue
                price={parseFloat(propOr(0, 'PRICE', modifier))}
              />
            </div>
          ) : (
            <div className={s.tooltipInactive}>Есть в других сочетаниях</div>
          )}
        </div>
      )}
    >
      {linkToModifier}
    </Tooltip>
  )
}

ModifierItem.propTypes = {
  isDesktop: bool,
  modifier: object
}

const ModifierItemWrapper = display(ModifierItem)

const ProductModifier = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false)

  const onCollapse = () => () => {
    setIsOpen(status => not(status))
  }

  const countModifiers = length(propOr([], 'MODIFIERS', item))

  return (
    <div className={s.modifier}>
      <Text>{prop('TITLE', item)}</Text>
      <div className={s.modifierItems}>
        {compose(
          map(modifier => (
            <ModifierItemWrapper
              key={prop('TITLE', modifier)}
              modifier={modifier}
            />
          )),
          slice(0, isOpen ? countModifiers : DEFAULT_OPEN),
          propOr([], 'MODIFIERS')
        )(item)}
        {countModifiers > DEFAULT_OPEN ? (
          <div
            className={cx(s.modifierItem, s.modifierMore, {
              [s.modifierMoreOpen]: isOpen
            })}
            onClick={onCollapse()}
            role='presentation'
          >{isOpen ? 'Скрыть' : `Еще ${countModifiers - DEFAULT_OPEN}`}</div>
        ) : null}
      </div>
    </div>
  )
}

ProductModifier.propTypes = {
  item: object
}

const ProductModifiers = ({ productId = '' }) => {
  const modifiers = useSelector(({ productList }) => modifiersSelector(productList, productId))
  return (
    <div className={s.modifiers}>
      {modifiers.map(item => (
        <ProductModifier
          key={prop('TITLE', item)}
          item={item}
        />
      ))}
    </div>
  )
}

ProductModifiers.propTypes = {
  productId: string
}

export default ProductModifiers
