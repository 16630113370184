import React, { FC } from 'react'

import Icon from 'components/Icon'
import Modal from 'containers/Modal'
import Text from 'UI/Text'

import s from './ResultPopup.scss'

interface IResultPopup {
  msgSuccess?: string | React.ReactNode
  msgError?: string | React.ReactNode
  popupSuccess?: string
  popupError?: string
}

const ResultPopup: FC<IResultPopup> = ({
  msgSuccess = 'Успешно отправлено!',
  msgError = 'Приносим свои извинения! Произошла ошибка. Повторите попытку.',
  popupSuccess = 'resultSuccess',
  popupError = 'resultError'
}) => (
  <>
    {/* @ts-ignore */}
    <Modal id={popupSuccess}
      size='small'>
      <div className={s.block}>
        <Icon icon='status-luck'
          size='middle' />
        <Text>{msgSuccess}</Text>
      </div>
    </Modal>
    {/* @ts-ignore */}
    <Modal id={popupError}>
      <div className={s.block}>
        <Icon icon='status-error' />
        <Text>{msgError}</Text>
      </div>
    </Modal>
  </>
)

export default ResultPopup
