// @ts-nocheck
import React, { FC, FormEventHandler } from 'react'

import { compose, has } from 'ramda'
import { connect } from 'react-redux'
import { inject } from 'react-tunnel-16'
import { Field, reduxForm } from 'redux-form'

import InputWithErrors from 'components/InputWithErrors'
import Link from 'components/Link'
import submit from 'containers/Forms/submit'
import {
  currentContractorIdSelector,
  currentContractorNameSelector
} from 'redux/modules/personal'
import Button from 'UI/Button'
import { FieldInput } from 'UI/Input'
import Text from 'UI/Text'
import { FieldTextarea } from 'UI/Textarea'
import {
  errorTestEmail,
  errorTestPhone,
  testEmail,
  testPhone
} from 'utils/testRegexp'

import s from './FormSecurity.scss'

const fieldset = [
  {
    name: 'user_name',
    placeholder: 'Ваше имя',
    component: FieldInput,
    type: 'text'
  },
  {
    name: 'user_phone',
    placeholder: 'Ваш телефон',
    component: InputWithErrors,
    type: 'tel'
  },
  {
    name: 'user_email',
    placeholder: 'Ваш адрес электронной почты',
    component: InputWithErrors,
    type: 'email'
  }
]

interface IFormSecurity {
  contractorName: string
  contractorId: string
  clientApi: object
  handleSubmit: FormEventHandler<HTMLFormElement>
  invalid: boolean
  submitting?: boolean
}

const FormSecurity: FC<IFormSecurity> = ({
  contractorName,
  contractorId,
  clientApi,
  handleSubmit,
  invalid,
  submitting
}) => (
  <form
    className={s.form}
    onSubmit={handleSubmit(
      submit({
        formName: 'feedback_sb',
        clientApi,
        contractor_id: contractorId,
        contractor_name: contractorName
      })
    )}
  >
    <Text>
      Изложите описание проблемы/обращения или жалобы на действия сотрудников
      компании «Рельеф-Центр».
    </Text>
    <fieldset className={s.fieldset}>
      <Text semibold>Контактная информация (по желанию)</Text>
      {fieldset.map(field => (
        <Field
          key={field.name}
          {...field}
        />
      ))}
    </fieldset>
    <fieldset className={s.fieldset}>
      <Text semibold>Сообщение</Text>
      <Field
        component={FieldTextarea}
        name='comment'
        placeholder='Сообщение'
        required
      />
      <Text>
        Ваше сообщение будет доставлено напрямую в Службу Безопасности компании
        «Рельеф-Центр». Это конфиденциальный канал информирования Службы
        Безопасности о нарушениях со стороны сотрудников компании
        «Рельеф-Центр». Ваше сообщение будет конфиденциально и недоступно
        третьим лицам, включая техническую поддержку сайта.
      </Text>
    </fieldset>
    <Text>
      Отправляя форму я даю
      <Link to='/documents/consent'
        color='blue'>
        &nbsp;согласие на обработку персональных данных&nbsp;
      </Link>
      в соответствии с
      <Link to='/documents/policy'
        color='blue'>
        &nbsp;политикой конфиденциальности&nbsp;
      </Link>
      .
    </Text>
    <Button type='submit'
      disabled={submitting || invalid}>
      Отправить
    </Button>
  </form>
)

export default compose(
  reduxForm({
    form: 'securityForm',
    // eslint-disable-next-line complexity
    validate: values => {
      const errors = {
        user_email:
          values.user_email && !testEmail(values.user_email)
            ? errorTestEmail
            : '',
        user_phone:
          values.user_phone && !testPhone(values.user_phone)
            ? errorTestPhone
            : '',
        comment: !has('comment', values) ? 'Обязательное поле' : ''    
      }

      return errors
    }
  }),
  connect(({ personal }) => ({
    contractorName: currentContractorNameSelector(personal),
    contractorId: currentContractorIdSelector(personal)
  })),
  inject(({ helpers: { clientApi } }) => ({ clientApi }))
)(FormSecurity)
