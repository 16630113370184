/* eslint-disable complexity */
import React, { useState, useCallback, useEffect, useRef } from 'react'

import cx from 'classnames'
import { array, bool, func, number, oneOfType, string } from 'prop-types'
import {
  isEmpty,
  length,
  path,
  pathOr,
  prop,
  propOr,
  reduce,
  toUpper,
  inc
} from 'ramda'
import { connect } from 'react-redux'
import { useMouseWheel } from 'react-use'
import { compose } from 'redux'

import CurrencyValue from 'components/CurrencyValue'
import Icon from 'components/Icon'
import Picture from 'components/Picture'
import Scrollbar from 'components/Scrollbar'
import CartMove from 'containers/Cart/CartMove'
import { getCartName } from 'containers/Cart/CartName'
import { forDesktop } from 'decorators/device'
import { bookmarkNameSelector } from 'redux/modules/bookmarks'
import { showModal } from 'redux/modules/modal'
import {
  getBasketParams,
  switchItemInGroup,
  setCartReset,
  cartActionSelector,
  cartsSelector
} from 'redux/modules/productList'
import {
  setLocalStorage,
  countClickSelector,
  notificationsSelector
} from 'redux/modules/settings'
import formatAmount from 'utils/formatAmount'
import noun from 'utils/noun'

import s from './CartInfo.scss'

const INTERVAL = 1000
const TICK = 3
const HEIGHT = 300
const CLICKS = 2

const CartInfo = ({
  onShowModal = () => {},
  onSetCartReset = () => {},
  onSetLocalStorage = () => {},
  isFixed = false,
  items = [],
  carts = [],
  action = 'add',
  cart = 0,
  countClick = 0,
  catalog = 'main',
  bookmarkName = '',
  location = '/',
  from = '',
  isCartNotification = true
}) => {
  const isInit = useRef(true)

  const [count, setCount] = useState(0)

  const mouseWheel = useMouseWheel()

  const onClose = useCallback(() => {
    onSetCartReset()
  }, [onSetCartReset])

  useEffect(() => {
    const iid = setInterval(() => {
      setCount(currCount => currCount + 1)
    }, INTERVAL)
    return () => {
      clearInterval(iid)
    }
  }, [])

  useEffect(() => {
    if (count >= TICK) {
      onClose()
    }
  }, [count, onClose])

  useEffect(() => {
    if (mouseWheel > 0) {
      setCount(TICK)
    }
  }, [mouseWheel])

  useEffect(() => {
    if (isInit.current) {
      isInit.current = false
    } else {
      setCount(TICK)
    }
  }, [location])

  const isAdd = action === 'add'

  const isDelete = action === 'remove'

  const isMove = action === 'move'

  const isCopy = action === 'copy'

  const cnt = length(items)

  const onMove = () => () => {
    onShowModal('cart-move')
  }

  const onMouseEnter = useCallback(() => {
    setCount(0)
  }, [])

  const onMouseLeave = useCallback(() => {
    setCount(0)
  }, [])

  const onCloseNotification = () => () => {
    onSetLocalStorage({ key: 'countClick', value: inc(countClick) })
    setCount(TICK)
    if (countClick > 0 && countClick < CLICKS) {
      onShowModal('cartActionSettings')
    }
  }

  const cartName =
    catalog === 'bookmark'
      ? bookmarkName
      : getCartName(isEmpty(cart) ? 0 : cart, carts)

  if (!isCartNotification) {
    return null
  }

  return (
    <div
      className={cx(s.info, {
        [s.infoFixed]: isFixed
      })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className={s.wrapper}>
        <div className={s.title}>
          {isAdd && (
            <div className={cx(s.titleItem, s.titleAdd)}>
              {typeof cart === 'number'
                ? `Товар добавлен в корзину ${cartName}`
                : `Товар добавлен в корзины: ${cartName}`}
            </div>
          )}
          {isDelete && (
            <div className={cx(s.titleItem, s.titleDelete)}>
              Товар удален из корзины {cartName}
            </div>
          )}
          {isMove && (
            <div className={cx(s.titleItem, s.titleMove)}>
              {cnt}{' '}
              {noun(cnt, [
                'товар перемещен',
                'товара перемещено',
                'товаров перемещено'
              ])}{' '}
              в корзину {cartName}
            </div>
          )}
          {isCopy && (
            <div className={cx(s.titleItem, s.titleMove)}>
              {cnt}{' '}
              {noun(cnt, [
                'товар скопирован',
                'товара скопировано',
                'товаров скопировано'
              ])}{' '}
              в корзину {cartName}
            </div>
          )}
          <div
            role='presentation'
            className={s.close}
            title='Закрыть уведомление'
            onClick={onCloseNotification()}
          >
            <Icon icon='close' />
          </div>
        </div>
        <div className={s.hr} />
        <div className={s.products}>
          <Scrollbar maxHeight={HEIGHT}
            minScrollHeight={15}>
            {items.map(item => {
              const countProduct = pathOr(
                1,
                [
                  'BASKETS',
                  toUpper(catalog),
                  isMove || isCopy ? from : cart,
                  'QUANTITY'
                ],
                item
              )
              const isPointsProduct = pathOr(
                false,
                [
                  'BASKETS',
                  toUpper(catalog),
                  isMove || isCopy ? from : cart,
                  'FOR_POINTS'
                ],
                item
              )
              return (
                <div key={prop('ID', item)}
                  className={s.product}>
                  <div className={s.productImage}>
                    <Picture
                      backgroundSize='contain'
                      className={s.image}
                      src={propOr('', 'PREVIEW_PICTURE_PATH', item)}
                      alt={propOr('', 'NAME', item)}
                    />
                  </div>
                  <div className={s.productName}>
                    {propOr('', 'NAME', item)}
                  </div>
                  <div className={s.productCount}>
                    {formatAmount(countProduct)} шт.
                  </div>
                  <div className={s.productPrice}>
                    <CurrencyValue
                      isPoints={isPointsProduct}
                      price={propOr(0, 'SUM', item)}
                    />
                  </div>
                </div>
              )
            })}
          </Scrollbar>
        </div>
        <div className={s.hr} />
        {isAdd && (
          <div className={s.action}>
            <div className={cx(s.button, s.add)}>
              <Icon icon='cart-change-mini'
                className={s.moveIcon} />
              <div role='presentation'
                className={s.addText}
                onClick={onMove()}>
                Переложить товар
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

CartInfo.propTypes = {
  onShowModal: func,
  onSetCartReset: func,
  onSetLocalStorage: func,
  items: array,
  carts: array,
  cart: oneOfType([number, array]),
  countClick: number,
  isFixed: bool,
  bookmarkName: string,
  location: string,
  from: string,
  action: string,
  catalog: string,
  isCartNotification: bool
}

const CartInfoWrapper = ({ isActive, items, ...props }) => {
  const products = isEmpty(items)
    ? {}
    : reduce((acc, item) => ({ ...acc, [prop('ID', item)]: true }), {}, items)
  return (
    <>
      <CartMove products={products} />
      {isActive && <CartInfo items={items}
        {...props} />}
    </>
  )
}

CartInfoWrapper.propTypes = {
  isActive: bool,
  items: array
}

export default compose(
  connect(
    ({ bookmarks, productList, router, settings }) => ({
      ...cartActionSelector(productList),
      carts: cartsSelector(productList, settings),
      basketParams: getBasketParams(productList),
      bookmarkName: bookmarkNameSelector(bookmarks),
      location: `${path(['location', 'pathname'], router)}${path(
        ['location', 'search'],
        router
      )}`,
      isCartNotification: pathOr(
        true,
        ['check_change', 'ACTIVE'],
        notificationsSelector(settings)
      ),
      countClick: countClickSelector(settings)
    }),
    {
      onShowModal: showModal,
      onSetCartReset: setCartReset,
      onSwitchItemInGroup: switchItemInGroup,
      onSetLocalStorage: setLocalStorage
    }
  ),
  forDesktop
)(CartInfoWrapper)
