/* eslint-disable complexity */
import React, { PureComponent } from 'react'

import highlightMatch from 'autosuggest-highlight/match'
import highlightParse from 'autosuggest-highlight/parse'
import cx from 'classnames'
import { push } from 'connected-react-router'
import debounce from 'lodash.debounce'
import { array, bool, func, string, number } from 'prop-types'
import { pathOr, length, prop, propOr, equals, path, composeP } from 'ramda'
import Autosuggest from 'react-autosuggest'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'

import CurrencyValue from 'components/CurrencyValue'
import Icon from 'components/Icon'
import Picture from 'components/Picture'
import { setAnalyticsProps } from 'helpers/metaTags'
import { setCatalogVisible } from 'redux/modules/catalog'
import { isDesktopSelector } from 'redux/modules/device'
import {
  bookmarkSelector,
  isBookmarkSelector,
  isLoyaltySelector
} from 'redux/modules/settings'
import {
  initHistory,
  fetch,
  clear,
  clearValue,
  pushHistory,
  clearHistory,
  pushToUrl,
  clearUrl,
  suggestionsSelector
} from 'redux/modules/suggestions'
import parse from 'utils/querystring'

import s from './SearchLine.scss'

const SYMBOLS = 0
const NUMBER_350 = 350
const TIMEOUT_100 = 100
const SIZE_38 = 38
const SIZE_20 = 20

const urlsSelector = createSelector(
  state => state,
  state => path(['urls', 0], state)
)

const queryrouterSelector = createSelector(
  state => state,
  state => propOr('', 'q', parse(path(['location', 'search'], state)))
)

const getSuggestionValue = suggestion => prop('name', suggestion)

const getSectionSuggestions = section => prop('items', section)

const shouldRenderSuggestions = () => true

@connect(
  ({ settings, suggestions, router, device }) => ({
    isLoyalty: isLoyaltySelector(settings),
    isBookmark: isBookmarkSelector(settings),
    bookmark: bookmarkSelector(settings),
    suggestions: suggestionsSelector(suggestions),
    url: urlsSelector(suggestions),
    query: queryrouterSelector(router),
    isDesktop: isDesktopSelector(device)
  }),
  {
    initHistory,
    push,
    setCatalogVisible,
    pushHistory,
    clearHistory,
    pushToUrl,
    clearUrl,
    fetchSuggestions: fetch,
    clearSuggestions: clear,
    clearValue
  }
)
export default class SearchLine extends PureComponent {
  static propTypes = {
    initHistory: func,
    fetchSuggestions: func,
    clearSuggestions: func,
    push: func,
    clearValue: func,
    pushHistory: func,
    clearHistory: func,
    pushToUrl: func,
    clearUrl: func,
    suggestions: array,
    query: string,
    url: string,
    isLoyalty: bool,
    isBookmark: bool,
    bookmark: number,
    isDesktop: bool,
    alwaysRender: bool
  }

  static defaultProps = {
    initHistory: () => {},
    fetchSuggestions: () => {},
    clearSuggestions: () => {},
    push: () => {},
    clearValue: () => {},
    pushHistory: () => {},
    clearHistory: () => {},
    pushToUrl: () => {},
    clearUrl: () => {},
    suggestions: [],
    bookmark: 0,
    url: '',
    isLoyalty: false,
    isBookmark: false,
    alwaysRender: false
  }

  constructor(props) {
    super(props)
    this.searchRef = React.createRef()
  }

  state = {
    value: ''
  }

  componentDidMount() {
    this.props.initHistory()
    if (this.props.query) {
      this.setState({ value: this.props.query })
    }
    if (this.props.alwaysRender && this.autosuggest) {
      this.autosuggest.input.focus()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.query !== prevProps.query) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ value: this.props.query })
    }
  }

  handleChange = (event, { newValue }) => {
    this.setState({ value: newValue })
  }

  handleKeyDown = event => {
    const { value } = this.state
    if (event.key === 'Enter' && value.length) {
      this.onPush({ name: value })
    }
  }

  handleSubmitForm = () => {
    const { value } = this.state
    if (value.length) {
      this.onPush({ name: value })
    }
  }

  handleResetSuggestions = () => {
    this.props.clearSuggestions()
  }

  handleResetValue = () => {
    this.setState({ value: '' })
    this.props.clearValue()
  }

  handleSuggestionsFetchRequested = ({ value }) => {
    const { isLoyalty } = this.props
    const type = isLoyalty ? 'loyalty' : 'main'
    this.searchSuggestion({ value, type })
  }

  handleSuggestionSelected = (event, { suggestion }) => {
    this.onPush(suggestion)
  }

  handleClearHistorys = event => {
    this.props.clearHistory()
    event.stopPropagation()
  }

  handleClearHistory = item => event => {
    this.props.clearHistory(prop('name', item))
    event.stopPropagation()
  }

  setRef = el => {
    this.autosuggest = el
  }

  onPush = suggestion => {
    const { isLoyalty, isBookmark, bookmark } = this.props
    let prefix = isLoyalty ? '/loyalty' : ''
    prefix = isBookmark ? '/bookmark' : prefix
    const code = prop('code', suggestion)
    const name = prop('name', suggestion)
    const id = prop('id', suggestion)
    this.onNavigate()
    if (equals(code, 'products') && id) {
      return this.props.pushToUrl(
        `${prefix}${
          isBookmark ? `/${bookmark}` : ''
        }/catalog/product/${id}`
      )
    }
    if (equals(code, 'sections') && id) {
      return this.props.pushToUrl(
        `${prefix}${
          isBookmark ? `/${bookmark}` : ''
        }/catalog/products/${id}`
      )
    }
    return this.props.pushToUrl(
      `${prefix}${
        isBookmark ? `/${bookmark}` : ''
      }/search?q=${encodeURIComponent(name)}`
    )
  }

  onNavigate = debounce(() => {
    const { url } = this.props
    const { value } = this.state
    return composeP(
      () => this.searchRef.current.focus(),
      () => this.props.clearUrl(),
      () => value && this.props.pushHistory(value),
      () => this.props.push(url)
    )()
  }, TIMEOUT_100)

  searchSuggestion = debounce(search => {
    if (length(pathOr('', ['value'], search)) >= SYMBOLS) {
      this.props.fetchSuggestions(search)
    } else {
      this.props.clearSuggestions()
    }
  }, NUMBER_350)

  renderSuggestion = (suggestion, { query }) => {
    const {isDesktop} = this.props
    const suggestionText = prop('name', suggestion)
    const code1c = prop('code1c', suggestion)
    const suggestionCode = prop('code', suggestion)
    const suggestionPrice = propOr(0, 'price', suggestion)
    const image = prop('image', suggestion)
    const matches = highlightMatch(suggestionText, query)
    const parts = highlightParse(suggestionText, matches)
    return (
      <>
        <div className={s.hr} />
        <div
          className={s.item}
          alt={suggestionText}
          {...setAnalyticsProps({
            type: 'action',
            group: 'searchItem',
            action: suggestionCode,
            pos: prop('key', suggestion)
          })}
        >
          <div className={s.itemName}>
            <div className={s.itemDesc}>
              {suggestionCode === 'history' && (
                <div className={s.icon}>
                  <Icon className={s.iconHistory}
                    icon='clock' />
                </div>
              )}
              {suggestionCode === 'suggests' && (
                <div className={s.icon}>
                  <Icon className={s.iconSuggest}
                    icon='search' />
                </div>
              )}
              {suggestionCode === 'sections' && (
                <div className={cx(s.image, s.imageSection)}>
                  {image ? (
                    <Picture
                      backgroundSize='contain'
                      src={image}
                      alt={suggestionText}
                      height={38}
                      width={38}
                    />
                  ) : (
                    <Icon icon='photo'
                      className={s.imageNone} />
                  )}
                </div>
              )}
              {suggestionCode === 'products' && (
                <div className={cx(s.image, s.imageProduct)}>
                  {image ? (
                    <Picture
                      backgroundSize='contain'
                      src={image}
                      alt={suggestionText}
                      height={isDesktop ? SIZE_38 : SIZE_20}
                      width={isDesktop ? SIZE_38 : SIZE_20}
                    />
                  ) : (
                    <Icon icon='photo'
                      className={s.imageNone} />
                  )}
                </div>
              )}
              {suggestionCode === 'products' && code1c && isDesktop && (
                <div className={s.code1c}>{code1c}</div>
              )}
              <div className={s.name}>
                {isDesktop ? (
                  parts.map(part => {
                    const className = part.highlight ? s.highlight : null
                    return (
                      <span className={className}
                        key={part.text}>
                        {part.text}
                      </span>
                    )
                  })
                ) : (
                  <div className={s.nameText}>
                    <span className={s.highlight}>{code1c}</span>
                    {suggestionText}
                  </div>
                )}
              </div>
            </div>
            {suggestionCode === 'products' &&
              suggestionPrice > 0 &&
              isDesktop && (
              <div className={s.itemPrice}>
                <CurrencyValue price={suggestionPrice} />
              </div>
            )}
          </div>
          <div className={s.itemAction}>
            {suggestionCode === 'history' && (
              <div
                role='presentation'
                className={s.action}
                onClick={this.handleClearHistory(suggestion)}
              >
                Удалить
              </div>
            )}
            {suggestionCode !== 'history' && (
              <div className={s.actionNext}>
                <Icon className={s.iconNext}
                  icon='next' />
              </div>
            )}
          </div>
        </div>
      </>
    )
  }

  renderSectionTitle = section => (
    <>
      <div className={s.hr} />
      <div className={s.title}>
        <div className={s.titleName}>{prop('title', section)}</div>
        {equals(prop('code', section), 'history') && (
          <div
            role='presentation'
            className={s.titleAction}
            onClick={this.handleClearHistorys}
          >
            Очистить
          </div>
        )}
      </div>
    </>
  )

  render() {
    const { suggestions, isLoyalty, isBookmark, alwaysRender } = this.props
    const { value } = this.state

    const inputProps = {
      placeholder: !isLoyalty
        ? 'Поиск в каталоге по названию, артикулу, производителю'
        : 'Поиск в каталоге подарков по названию, артикулу, производителю',
      value,
      onChange: this.handleChange,
      onKeyDown: this.handleKeyDown
    }
    const theme = {
      container: s.container,
      containerOpen: s.containerOpen,
      input: cx(s.input, {
        [s.inputLoyalty]: isLoyalty,
        [s.inputBookmark]: isBookmark
      }),
      suggestionsContainer: cx(s.suggestionsContainer, {
        [s.suggestionsContainerLoyalty]: isLoyalty,
        [s.suggestionsContainerBookmark]: isBookmark
      }),
      suggestion: s.suggestion,
      suggestionFirst: s.suggestionFirst,
      suggestionHighlighted: s.suggestionHighlighted
    }
    return (
      <div
        className={s.search}
        itemProp='potentialAction'
        itemScope
        itemType='http://schema.org/SearchAction'
      >
        <Autosuggest
          ref={this.setRef}
          alwaysRenderSuggestions={alwaysRender}
          suggestions={suggestions}
          shouldRenderSuggestions={shouldRenderSuggestions}
          onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.handleResetSuggestions}
          getSuggestionValue={getSuggestionValue}
          getSectionSuggestions={getSectionSuggestions}
          renderSuggestion={this.renderSuggestion}
          renderSectionTitle={this.renderSectionTitle}
          onSuggestionSelected={this.handleSuggestionSelected}
          inputProps={inputProps}
          theme={theme}
          focusInputOnSuggestionClick={false}
          multiSection
        />
        {!!value && (
          <div
            role='presentation'
            className={s.close}
            onClick={this.handleResetValue}
          >
            <Icon icon='close'
              className={s.closeIcon} />
          </div>
        )}
        <button
          type='button'
          onClick={this.handleSubmitForm}
          ref={this.searchRef}
          className={cx(s.submit, {
            [s.submitLoyalty]: isLoyalty,
            [s.submitOrigin]: !isLoyalty && !isBookmark,
            [s.submitBookmark]: isBookmark
          })}
        >
          <Icon
            className={s.iconSearch}
            icon='search'
          />
        </button>
      </div>
    )
  }
}
