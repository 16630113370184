/* eslint-disable complexity */
import React, { useEffect, useCallback } from 'react'

import { func, string } from 'prop-types'
import { isEmpty } from 'ramda'
import { compose } from 'redux'

import Icon from 'components/Icon'
import { MistakeForm } from 'containers/Forms'
import { forDesktop } from 'decorators/device'
import { modalActions } from 'decorators/modal'
import { forAuth } from 'decorators/user'
import Text from 'UI/Text'

import s from './ProductTabs.scss'

const KEY_CODE_13 = 13

const FoundMistake = ({ selectedText = '', onSetSelectedText = () => {}, showModal, hideModal }) => {
  const onCloseModal = useCallback(() => {
    hideModal()
    onSetSelectedText('')
  }, [])

  const handleKeyDown = useCallback(e => {
    if (
      e.keyCode === KEY_CODE_13 &&
      e.ctrlKey &&
      !isEmpty(selectedText)
    ) {
      showModal('mistakeForm')
    }
  }, [selectedText, showModal])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [handleKeyDown])

  return (
    <>
      <div className={s.mistake}>
        <Icon icon='text-icon' />
        <Text>
          Ошибка в тексте? Выделите нужный фрагмент и нажмите CTRL+ENTER
        </Text>
      </div>
      
      <MistakeForm
        selectedText={selectedText}
        onClose={onCloseModal}
        popupSuccess='mistakeSuccess'
      />
    </>
  )
}

FoundMistake.propTypes = {
  selectedText: string,
  onSetSelectedText: func,
  showModal: func,
  hideModal: func
}

export default compose(
  forAuth,
  forDesktop,
  modalActions
)(FoundMistake)
